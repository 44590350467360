
import { defineComponent } from "@vue/runtime-core";
import { timestampToDate } from "@/plugins/helpers/timeHelpers";
import { Parallax, ProfileCard, ContactForm } from "@/components";
import { UserData } from "@/apiServices/interface";
import ApiController from "@/apiServices/ApiController";

export default defineComponent({
  name: "About",
  components: { Parallax, ProfileCard, ContactForm },
  data() {
    return {
      usersList: [] as UserData[],
      pendingRequest: false,
      timestampToDate
    };
  },
  methods: {
    async getUsers() {
      if (this.pendingRequest) {
        return;
      }

      this.pendingRequest = true;
      const users = await ApiController.user.getFounders(4);

      users?.docs.map(doc =>
        this.usersList.push(({ id: doc.id, ...doc?.data() } as unknown) as UserData)
      );

      this.pendingRequest = false;
    }
  },

  created() {
    this.getUsers();
  }
});
